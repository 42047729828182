/**
 * Created by vladislav on 06/12/2022
 */

const ShortMetaLevel = function (location, progress) {
    this.location = location;
    this.progress = progress;

    const currentProgress = this.location.getProgress();
    if (progress < currentProgress) {
        this.type = Level.TYPE_PASSED;
    } else if (progress === currentProgress) {
        this.type = Level.TYPE_CURRENT;
    } else {
        this.type = Level.TYPE_NOTPASSED;
    }
};

ShortMetaLevel.prototype.onPressed = function () {
    cleverapps.focusManager.display({
        focus: "LevelPressedPlay",
        action: function (f) {
            cleverapps.meta.wantsToPlay(f);
        }
    });
};

ShortMetaLevel.prototype.isHard = function () {

};

ShortMetaLevel.prototype.isBonus = function () {

};

ShortMetaLevel.prototype.isTricky = function () {

};

ShortMetaLevel.prototype.getHumanReadableNumber = function () {
    return parseInt(this.location.locationId) * ShortMetaLocation.LEVELS_PER_LOCATION + this.progress + 1;
};

ShortMetaLevel.prototype.getReward = function () {
    return this.location.getReward(this.progress);
};