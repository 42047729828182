/**
 * Created by mac on 9/18/22
 */

const ShortMetaLocation = function (locationId) {
    BaseLocation.call(this, "shortmeta", locationId);
};

ShortMetaLocation.prototype = Object.create(BaseLocation.prototype);
ShortMetaLocation.prototype.constructor = ShortMetaLocation;

ShortMetaLocation.prototype.load = function (stored) {
    this.progress = stored.progress || 0;
};

ShortMetaLocation.prototype.getInfo = function () {
    return {
        progress: this.progress
    };
};

ShortMetaLocation.prototype.withStars = function () {
    return true;
};

ShortMetaLocation.prototype.changeProgress = function (progress) {
    this.setProgress(this.progress + progress);
};

ShortMetaLocation.prototype.setProgress = function (progress) {
    this.progress = progress;
    this.save();
};

ShortMetaLocation.prototype.getProgress = function () {
    return this.progress;
};

ShortMetaLocation.prototype.isCompleted = function () {
    return this.progress >= ShortMetaLocation.LEVELS_PER_LOCATION;
};

ShortMetaLocation.prototype.gamePlayed = function (game) {
    if (cleverapps.knockoutGame && cleverapps.knockoutGame.rumble.outcome !== Rumble.OUTCOME_WIN) {
        return;
    }

    this.multiplyRewards(game, this.getProgress());
    this.changeProgress(1);

    cleverapps.user.incLevel();

    if (this.isCompleted()) {
        cleverapps.meta.moveNextLocation();
        cleverapps.meta.refreshLocation();
    }
};

ShortMetaLocation.prototype.getReward = function (level) {
    return RewardsConfig.ShortMeta[level % ShortMetaLocation.LEVELS_PER_LOCATION];
};

ShortMetaLocation.prototype.multiplyRewards = function (game, level) {
    const reward = this.getReward(level);
    if (reward) {
        for (const type in reward) {
            let amount = game.rewards[type] * (reward[type].factor || 1);
            amount -= game.rewards[type];

            if (type === GameBase.REWARD_HARD) {
                game.addHardReward(amount);
            } else if (type === GameBase.REWARD_SOFT) {
                game.addSoftReward(amount);
            }
        }
    }
};

ShortMetaLocation.GetMainScene = function () {
    return ShortMetaScene;
};

ShortMetaLocation.LEVELS_PER_LOCATION = 10;
